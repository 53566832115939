import "css/style.css"
import {lazyLoading} from "./lazyload/lazyload-init"
import "@components/main-nav"
import "@components/main-header/main-header"
import "@components/main-header/main-header-search/main-header-search"
import "@components/items/menu-item/menu-item"

window.lazyloading = lazyLoading // add lazyloading to global window object

async function loadFancybox() {
  if (document.querySelectorAll("[data-fancybox]").length) {
    await import("./fancybox/fancybox")
  }
}
async function loadMain() {
  await import("./main")
  loadFancybox()
}
loadMain()

async function loadModals() {
  if (document.querySelectorAll(".c-modal").length) {
    await import("@components/modal/modal")
  }
}
loadModals()

async function loadFileInput() {
  if (document.querySelectorAll(".f-file").length) {
    await import("@components/fields/file-field/file-field")
  }
}
loadFileInput()

async function loadDateInput() {
  if (document.querySelectorAll(".f-date").length) {
    await import("@components/fields/date-field/date-field")
  }
}
loadDateInput()

async function loadPasswordField() {
  if (document.querySelectorAll(".f-text.--password").length) {
    await import("@components/fields/text-field/text-field--password")
  }
}
loadPasswordField()

async function loadCalendar() {
  if (document.querySelectorAll(".s-calendar").length) {
    await import("@components/sections/calendar-section/calendar-section")
  }
}
loadCalendar()

async function loadSlimSelect() {
  if (document.querySelectorAll(".slim-select").length) {
    await import("./slim-select/slim-select")
  }
}
loadSlimSelect()

async function loadAlerts() {
  if (document.querySelectorAll(".i-alert").length) {
    await import("@components/items/alert-item/alert-item")
  }
}
loadAlerts()

async function loadNotification() {
  if (document.querySelectorAll(".i-notification").length) {
    await import("@components/items/notification-item/notification-item")
  }
}
loadNotification()

async function loadFaqItem() {
  if (document.querySelectorAll(".i-faq").length) {
    await import("@components/items/faq-item/faq-item")
  }
}
loadFaqItem()

async function loadSwiper() {
  if (document.querySelectorAll(".swiper").length) {
    await import("./swiper/swiper")
  }
}
loadSwiper()

async function loadCheckboxSelectField() {
  if (document.querySelectorAll(".f-checkbox-select").length) {
    await import("@components/fields/checkbox-select-field")
  }
}
loadCheckboxSelectField()

async function loadOverlayScrollbars() {
  if (document.querySelectorAll(".u-scrollbar").length) {
    await import("./overlayscrollbars")
  }
}
loadOverlayScrollbars()

async function loadBaseForm() {
  if (document.querySelectorAll("form.--validate").length) {
    await import("@components/base-form/base-form")
  }
}
loadBaseForm()

async function label() {
  if (document.querySelectorAll(".f-wrap")) {
    await import("../components/fields/label-field/label-field")
  }
}

label()
